<template>
  <div class="oldApp">
    <iframe
      src="http://www.hiknt.com/"
      frameborder="0"
      name="fm"
      id="fme"
    ></iframe>
  </div>
</template>

<script>
  import { onMounted, nextTick } from 'vue'
  export default {
    name: 'OldApp',
    setup() {},
  }
</script>

<style lang="scss" scoped>
  .oldApp {
    width: 100%;
    min-height: 100vh;
    color: #fff;
    iframe {
      width: 100%;
      min-height: 95vh;
    }
  }
</style>
